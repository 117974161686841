import React from 'react'
import Link from 'next/link'
import Image from 'next/image'

export default function Footer() { 
  return (
    <footer className='lg:px-20 2xl:px-40 px-6 py-12 lg:py-28 bg-mainColor md:px-12 lg:px-20 flex flex-col md:flex-row'>
      <section className='flex flex-col col-span-2 lg:h-11/12 mb-6 w-full md:w-1/6'>
        <section>
          <Link href='/'>
            <a>
              <Image
                loading='lazy'
                src='/images/ANET_Logo_white.svg'
                alt='ANET Transport Co.'
                className='w-full h-full py-0'
                height={50}
                width={150}
              />
            </a>
          </Link>
        </section>
        <h2 className='text-white lg:text-xl lg:w-4/5 text-base lg:my-7'>
          A better way to travel
        </h2>
      </section>
      <section className='w-full md:w-85 flex md:ml-10 flex-col md:flex-row'>
        <section className='lg:w-55 flex mb-6 md:mb-0 md:1/2'>
          <section className='w-2/5 flex flex-col h-full mb-5 md:mb-0 md:w-1/3'>
            <h3 className='font-bold lg:text-xl text-white mb-4 capitalize text-lg'>
              quick links
            </h3>
            <ul className='flex flex-col h-full'>
              <li className='mb-4'>
                <Link href='/booking'>
                  <a className='text-white text-sm lg:text-base'>Book a trip</a>
                </Link>
              </li>
              <li className='mb-4'>
                <Link href='/about'>
                  <a className='text-white text-sm lg:text-base'>
                    Dsicover ANET
                  </a>
                </Link>
              </li>
              <li className='mb-4'>
                <Link href='/services'>
                  <a className='text-white text-sm lg:text-base'>
                    Our services
                  </a>
                </Link>
              </li>
              {/* <li className='mb-4'>
                <Link href='#'>
                  <a className='text-white text-sm lg:text-base'>
                    Travel Rewards
                  </a>
                </Link>
              </li> */}
            </ul>
          </section>
          <section className='w-55 flex flex-col h-full md:w-1/2 lg:w-2/3'>
            <h3 className='font-bold lg:text-xl text-white mb-4 capitalize text-lg'>
              contact us
            </h3>
            <ul className='flex flex-col h-full'>
              <li className='flex text-white text-sm lg:text-base mb-3'>
                <p className='mr-2'>Email: </p>
                <a href='mailto:wecare@anettransport.com'>
                  wecare@anettransport.com
                </a>
              </li>
              <li className='flex text-white text-sm lg:text-base mb-5'>
                <p className='mr-2'>Telephone: </p>
                <a href='tel:+2349065383758'>09065383758</a>
              </li>
              <section className='flex flex-col'>
                <h3 className='mb-4 font-bold text-base capitalize text-white'>
                  visit us
                </h3>
                <p className='text-white text-sm'>
                  Plot 4, 1st Avenue, Festac Town, Lagos
                </p>
              </section>
            </ul>
          </section>
        </section>
        <section className='lg:w-2/5 flex w-full md:w-1/2'>
          <section className='mt:mb-8 flex flex-col h-full mb-5 md:mb-0 lg:w-1/2 md:w-2/5 w-2/5'>
            <h3 className='font-bold lg:text-xl text-white mb-4 capitalize text-lg'>
              follow us
            </h3>
            <ul className='flex flex-col h-full'>
              <li className='mb-4'>
                <a
                  href='https://web.facebook.com/ANET-Transport-Co-105319352013862'
                  target='_blank'
                  rel='noopener noreferrer'
                  className='text-white text-sm lg:text-base mb-3'
                >
                  Facebook
                </a>
              </li>
              <li className='mb-4'>
                <a
                  href='https://www.instagram.com/anettransport.co/'
                  target='_blank'
                  rel='noopener noreferrer'
                  className='text-white text-sm lg:text-base mb-3'
                >
                  Instagram
                </a>
              </li>
              <li className='mb-4'>
                <a
                  href='https://twitter.com/AnetTransportCo'
                  target='_blank'
                  rel='noopener noreferrer'
                  className='text-white text-sm lg:text-base mb-3'
                >
                  Twitter
                </a>
              </li>
            </ul>
          </section>
          <section className='mt:mb-8 flex flex-col h-full md:w-3/5 lg:w-1/2 w-55'>
            <h3 className='font-bold lg:text-xl text-white mb-4 capitalize text-lg'>
              legal
            </h3>
            <ul className='flex flex-col h-full'>
              <li className='mb-4'>
                <Link href='/terms'>
                  <a className='text-white text-sm lg:text-base mb-3 capitalize'>
                    terms and conditions
                  </a>
                </Link>
              </li>
              <li className='mb-4'>
                <Link href='/privacy'>
                  <a className='text-white text-sm lg:text-base mb-3'>
                    Privacy
                  </a>
                </Link>
              </li>
            </ul>
          </section>
        </section>
      </section>
    </footer>
  )
}
